export var ua_locale = {
    next_btn: 'Далі',
    have_an_account: 'Вже є акаунт?',
    log_in: 'Увійти',
    sign_up: "Увійти / Зареєструватися",
    auth_title1: 'Ласкаво просимо',
    auth_title2: 'в Quanta',
    line_text: 'або',
    sign_up_with_google: 'Зареєструватися через Google',
    sign_up_with_apple: 'Зареєструватися через Apple',
    log_in_title: 'З поверненням',
    email_phone_input_label: 'Електронна пошта/Номер телефону',
    password_input_label: 'Пароль',
    didt_remember_password: 'Не пам’ятаєте пароль?',
    change_password_title: 'Новий пароль',
    password_check_input_label: 'Перевірте пароль',
    reset_password_title: 'Скинути пароль',
    email_input_label: 'Електронна пошта',
    email_verification_title: 'Підтвердження електронної пошти',
    email_verification_description: 'Будь ласка, введіть 6-значний код підтвердження, який був надісланий на',
    email_verification_description2: 'Якщо ви не бачите листа у вхідних, перевірте папку "Спам" або "Небажана пошта", оскільки він міг бути відфільтрований туди.',
    email_code_title: 'Код електронної пошти',
    wait_code: 'Чекайте, щоб надіслати код знову',
    not_get_code: 'Не отримали код?',
    phone_verification_title: 'Підтвердження телефону',
    phone_verification_text1: 'Будь ласка, введіть 6-значний код підтвердження',
    phone_verification_text2: 'який був надісланий на ваш номер телефону',
    input_phone_label: 'Телефон',
    input_code_label: 'Код',
    creating_account_title: 'Створення акаунту',
    terms_and_policy1: 'Створюючи акаунт',
    terms_and_policy2: ', я погоджуюсь з',
    terms_and_policy3: ' умовами ',
    terms_and_policy4: 'Угоди користувача',
    terms_and_policy5: ' та ',
    terms_and_policy6: 'Політикою конфіденційності',
    email_verification_code1: 'Будь ласка, введіть 6-значний код підтвердження',
    email_verification_code2: 'який було надіслано на',
    email_verification_code3: 'Код дійсний протягом 30 хвилин.',
    email_verification_label: 'Код підтвердження електронної пошти',
    home_adress_title: 'Домашня адреса',
    home_adress_description: 'Переконайтеся, що це ваша поточна адреса проживання.',
    full_adress_label: 'Повна адреса',
    postal_code_label: 'Поштовий індекс',
    city_label: 'Місто',
    region_label: 'Регіон',
    password_creation_title: 'Пароль',
    password_check_label: 'Перевірте пароль',
    personal_information_title: 'Особиста інформація',
    personal_information_description1: 'Будь ласка, надайте наступну інформацію, як зазначено',
    personal_information_description2: 'у вашому паспорті або посвідченні особи.',
    full_name_label: 'Повне ім’я',
    data_about_user: 'Дані повинні відповідати даним у паспорті або посвідченні особи.',
    date_birth_label: 'Дата народження',
    phone_verification_code_label: 'Код підтвердження телефону',
    residensy_title: 'Давайте вас підтверджемо',
    residensy_title_description: 'Перш за все, виберіть вашу країну проживання.',
    residency_text: 'Переконайтеся, що ваша країна проживання вказана правильно.',
    residency_option: "Місце проживання",
    citizenship_option: "Громадянство",
    back_btn_text: "Назад",
    document_select_title: 'Перевірка документа',
    document_select_description: 'Оберіть країну/регіон, який видав документ.',
    document_select_warning: 'Використовуйте дійсний документ, виданий урядом',
    document_select_text1: 'Тільки наступні документи, зазначені нижче, ',
    document_select_text2: 'будуть прийняті; усі інші документи будуть ',
    document_select_text3: 'відхилені.',
    id_card_text: 'Посвідчення особи',
    passport_text: 'Закордонний паспорт',
    kyc_info_title: 'Перевірка документа',
    kyc_info_description1: 'Зробіть фото обох сторін вашого ',
    kyc_info_description2: 'державного документа.',
    kyc_info_description3: 'паспорт',
    reqiurements_kyc1: 'Документ не прострочений',
    reqiurements_kyc2: 'Це оригінальний документ, не копія чи скан',
    reqiurements_kyc3: 'Зніміть будь-які чохли чи тримачі для карток, щоб уникнути відблисків чи розмиття',
    reqiurements_kyc4: 'Розмістіть документ на однотонному фоні',
    reqiurements_kyc5: 'Документ має бути рівним, без нахилу чи повороту.',
    picture_card_text: 'Перетягніть своє фото сюди або оберіть',
    picture_card_signed_text: 'Натисніть для перегляду',
    picture_box_front: 'Лицьова сторона посвідчення особи',
    picture_box_back: 'Зворотна сторона посвідчення особи',
    picture_passport: 'Фотографія паспорта',
    kyc_person_label: 'Перевірка особистості',
    kyc_person_description: 'Зробіть фото себе з документом у руках.',
    photo_label: 'Ваше фото',
    person_requirements1: 'Зображення має бути чітким, без розмиття чи пікселізації.',
    person_requirements2: 'Обличчя користувача та документ повинні бути повністю видимі та не обрізані.',
    person_requirements3: 'Інформація на документі має бути чітко видимою та не прикритою пальцями.',
    person_requirements4: 'Документ має бути рівним, без нахилу чи повороту.',
    verify_btn: 'Підтвердити',
    verify_main_text: 'Тепер давайте підтвердимо вашу особу, щоб використовувати всі функції Quanta.',
    acccount_created_title: 'акаунт створено',
    account_verified_title: 'акаунт підтверджено',
    registartion_success_title: 'Реєстрація успішна',
    auth_success_title: 'Авторизація успішна',
    auth_login: 'Тепер використовуйте всі функції Quanta',
    kyc_text: 'Вас буде повідомлено про результати пізніше.',
    kyc_title: 'KYC надіслано на перевірку',
    not_now: 'Не зараз',
    password_requirements1: "Щонайменше 8 символів",
    password_requirements2: "Щонайменше 1 цифра",
    password_requirements3: "Щонайменше 1 велика літера",
    password_requirements4: "Паролі мають збігатися",
    apple_btn: "Зареєструватися через Apple",
    adress_palceholder: "Адреса",
    first_name_placeholder: "Ім'я",
    last_name_placeholder: "Прізвище",
    invalid_file_type: 'Недійсний тип файлу. Будь ласка, завантажте зображення у форматі PNG, JPEG або JPG.',
    incorrect_code: 'Введений код неправильний. Будь ласка, спробуйте ще раз.',
    upload_file: 'Будь ласка, завантажте файл.',
    missing_token: 'Відсутній токен авторизації.',
    picture_missing: 'Відсутнє зображення документа (лицьова або зворотна сторона).',
    document_type: 'Тип документа або країна відсутні.',
    existing_document: 'Для цього користувача вже існує документ.',
    failed_kyc: 'Не вдалося відправити дані KYC.',
    unexpected_error: 'Виникла непередбачена помилка. Будь ласка, спробуйте пізніше.',
    password_changed: 'Пароль змінено. Поверніться на попередню сторінку.',
    failsed_reset_password: 'Не вдалося скинути пароль.',
    error_occured: 'Сталася помилка під час скидання.',
    login_failed: 'Вхід не вдався, будь ласка, спробуйте ще раз.',
    email_send_error: 'Помилка під час надсилання коду підтвердження на електронну пошту.',
    parsing_error: 'Сталася непередбачена помилка під час обробки відповіді.',
    documents_under_review: "Документи вже на розгляді",
    email_already_exist: "Електронна пошта вже існує",
};
