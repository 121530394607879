export var de_locale = {
    next_btn: 'Weiter',
    have_an_account: 'Haben Sie schon ein Konto?',
    log_in: 'Einloggen',
    sign_up: "Anmelden / Registrieren",
    auth_title1: 'Willkommen',
    auth_title2: 'bei Quanta',
    line_text: 'oder',
    sign_up_with_google: 'Mit Google anmelden',
    sign_up_with_apple: 'Mit Apple anmelden',
    log_in_title: 'Willkommen zurück',
    email_phone_input_label: 'Email/Telefonnummer',
    password_input_label: 'Passwort',
    didt_remember_password: 'Passwort vergessen?',
    change_password_title: 'Neues Passwort',
    password_check_input_label: 'Passwort bestätigen',
    reset_password_title: 'Passwort zurücksetzen',
    email_input_label: 'Email',
    email_verification_title: 'Email-Verifizierung',
    email_verification_description: 'Bitte geben Sie den 6-stelligen Bestätigungscode ein, der an',
    email_verification_description2: 'Wenn Sie die E-Mail nicht in Ihrem Posteingang sehen, überprüfen Sie bitte Ihren Spam- oder Junk-Ordner, da sie dorthin gefiltert worden sein könnte.',
    email_code_title: 'Email-Code',
    wait_code: 'Warten Sie, um den Code erneut zu senden',
    not_get_code: 'Haben Sie keinen Code erhalten?',
    phone_verification_title: 'Telefon-Verifizierung',
    phone_verification_text1: 'Bitte geben Sie den 6-stelligen Bestätigungscode ein',
    phone_verification_text2: 'der an Ihre Telefonnummer gesendet wurde',
    input_phone_label: 'Telefon',
    input_code_label: 'Code',
    creating_account_title: 'Konto erstellen',
    terms_and_policy1: 'Indem ich ein Konto erstelle',
    terms_and_policy2: ', stimme ich zu',
    terms_and_policy3: ' den ',
    terms_and_policy4: 'Nutzungsbedingungen',
    terms_and_policy5: ' und ',
    terms_and_policy6: 'Datenschutzrichtlinie',
    email_verification_code1: 'Bitte geben Sie den 6-stelligen Bestätigungscode ein',
    email_verification_code2: 'der an',
    email_verification_code3: 'Der Code ist 30 Minuten lang gültig.',
    email_verification_label: 'E-Mail-Verifizierungscode',
    home_adress_title: 'Heimadresse',
    home_adress_description: 'Stellen Sie sicher, dass dies Ihre aktuelle Adresse ist.',
    full_adress_label: 'Vollständige Adresse',
    postal_code_label: 'Postleitzahl',
    city_label: 'Stadt',
    region_label: 'Region',
    password_creation_title: 'Passwort',
    password_check_label: 'Passwort überprüfen',
    personal_information_title: 'Persönliche Informationen',
    personal_information_description1: 'Bitte geben Sie die folgenden Informationen an, wie sie',
    personal_information_description2: 'in Ihrem Reisepass oder Ausweis angegeben sind.',
    full_name_label: 'Vollständiger Name',
    data_about_user: 'Die Daten müssen mit denen im Reisepass oder Ausweis übereinstimmen.',
    date_birth_label: 'Geburtsdatum',
    phone_verification_code_label: 'Telefonbestätigungscode',
    residensy_title: 'Lassen Sie uns Sie verifizieren',
    residensy_title_description: 'Wählen Sie zuerst Ihr Land der Niederlassung aus.',
    residency_text: 'Stellen Sie sicher, dass Ihr Land korrekt ist.',
    residency_option: "Wohnsitz",
    citizenship_option: "Staatsbürgerschaft",
    back_btn_text: "Zurück",
    document_select_title: 'Dokumentenüberprüfung',
    document_select_description: 'Wählen Sie das Ausstellungsland/Region Ihres Dokuments aus.',
    document_select_warning: 'Verwenden Sie ein gültiges, von der Regierung ausgestelltes Dokument',
    document_select_text1: 'Nur die unten aufgeführten Dokumente ',
    document_select_text2: 'werden akzeptiert; alle anderen Dokumente werden ',
    document_select_text3: 'abgelehnt.',
    id_card_text: 'Personalausweis',
    passport_text: 'Reisepass',
    kyc_info_title: 'Dokumentenüberprüfung',
    kyc_info_description1: 'Machen Sie ein Foto von beiden Seiten Ihres von der Regierung ausgestellten ',
    kyc_info_description2: 'Personalausweises.',
    kyc_info_description3: 'Reisepass',
    reqiurements_kyc1: 'Das Dokument ist nicht abgelaufen',
    reqiurements_kyc2: 'Es handelt sich um ein Originaldokument, keine Kopie oder einen Scan',
    reqiurements_kyc3: 'Entfernen Sie alle Kartenhüllen oder Abdeckungen, um Reflexionen oder Unschärfen zu vermeiden',
    reqiurements_kyc4: 'Legen Sie das Dokument vor einen einfarbigen Hintergrund',
    reqiurements_kyc5: 'Das Dokument sollte gerade gehalten werden, nicht geneigt oder gedreht.',
    picture_card_text: 'Legen Sie Ihr Foto hier ab oder wählen Sie aus',
    picture_card_signed_text: 'Klicken Sie, um zu durchsuchen',
    picture_box_front: 'Vorderseite des Personalausweises',
    picture_box_back: 'Rückseite des Personalausweises',
    picture_passport: 'Bild des Reisepasses',
    kyc_person_label: 'Persönlichkeitsprüfung',
    kyc_person_description: 'Machen Sie ein Foto von sich und Ihrem Dokument in Ihren Händen.',
    photo_label: 'Ihr Foto',
    person_requirements1: 'Das Bild muss scharf sein, ohne Unschärfe oder Pixelbildung.',
    person_requirements2: 'Sowohl das Gesicht des Benutzers als auch das Dokument müssen vollständig sichtbar und nicht beschnitten sein.',
    person_requirements3: 'Die Informationen auf dem Dokument müssen klar sichtbar und nicht von Fingern verdeckt sein.',
    person_requirements4: 'Das Dokument sollte gerade gehalten werden, nicht geneigt oder gedreht.',
    verify_btn: 'Verifizieren',
    verify_main_text: 'Lassen Sie uns nun Ihre Identität verifizieren, um alle Funktionen von Quanta zu nutzen.',
    acccount_created_title: 'Konto erstellt',
    account_verified_title: 'Konto verifiziert',
    registartion_success_title: 'Registrierung erfolgreich',
    auth_success_title: 'Anmeldung erfolgreich',
    auth_login: 'Nutzen Sie jetzt alle Funktionen von Quanta',
    kyc_text: 'Sie werden später über die Ergebnisse benachrichtigt.',
    kyc_title: 'KYC zur Überprüfung gesendet',
    not_now: 'Nicht jetzt',
    password_requirements1: "Mindestens 8 Zeichen",
    password_requirements2: "Mindestens 1 Zahl",
    password_requirements3: "Mindestens 1 Großbuchstabe",
    password_requirements4: "Passwörter müssen übereinstimmen",
    apple_btn: "Mit Apple registrieren",
    adress_palceholder: "Adresse",
    first_name_placeholder: "Vorname",
    last_name_placeholder: "Nachname",
    invalid_file_type: 'Ungültiger Dateityp. Bitte laden Sie ein PNG-, JPEG- oder JPG-Bild hoch.',
    incorrect_code: 'Der eingegebene Code ist falsch. Bitte versuchen Sie es erneut.',
    upload_file: 'Bitte laden Sie eine Datei hoch.',
    missing_token: 'Autorisierungstoken fehlt.',
    picture_missing: 'Bild von Vorder- oder Rückseite des Dokuments fehlt.',
    document_type: 'Dokumenttyp oder Land fehlt.',
    existing_document: 'Ein Dokument existiert bereits für diesen Benutzer.',
    failed_kyc: 'Fehler beim Einreichen der KYC-Daten.',
    unexpected_error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
    password_changed: 'Passwort geändert. Kehren Sie zur vorherigen Seite zurück.',
    failsed_reset_password: 'Passwort zurücksetzen fehlgeschlagen.',
    error_occured: 'Fehler beim Zurücksetzen aufgetreten.',
    login_failed: 'Anmeldung fehlgeschlagen, bitte versuchen Sie es erneut.',
    email_send_error: 'Fehler beim Senden des E-Mail-Bestätigungscodes.',
    parsing_error: 'Ein unerwarteter Fehler ist beim Parsen der Antwort aufgetreten.',
    documents_under_review: "Dokumente werden bereits überprüft",
    email_already_exist: "E-Mail existiert bereits",
};
