import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import InfoIcon from '../../assets/img/icons8-info-240.svg';
import { FormattedMessage } from "react-intl";
import { InfoContainerBg, InfoImg, VerifyText } from "../../assets/styles/logIn.style";
import { PositionLayout } from "../../assets/styles/mainPageStyles";
export var InfoContainer = function (_a) {
    var messageId = _a.messageId, defaultMessage = _a.defaultMessage;
    return (_jsx(PositionLayout, { margin: "5% 0px 0px 0px", children: _jsxs(InfoContainerBg, { children: [_jsx(InfoImg, { width: 25, height: 22, src: InfoIcon }), _jsx(VerifyText, { style: { color: '#c0b8b8', textIndent: 0, marginBottom: 0 }, as: 'p', children: _jsx(FormattedMessage, { id: messageId, defaultMessage: defaultMessage }) })] }) }));
};
