var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { create } from 'zustand';
// Zustand store for managing general user data
export var useDataStore = create(function (set) { return ({
    // Object that stores user-related data
    userDataObj: {},
    // Function to dynamically add or update a property in userDataObj
    addData: function (prop, value) {
        return set(function (state) {
            var _a;
            return ({
                userDataObj: __assign(__assign({}, state.userDataObj), (_a = {}, _a[prop] = value, _a)),
            });
        });
    },
    // Function to reset userDataObj to an empty object
    resetUserDataObj: function () { return set({ userDataObj: {} }); },
}); });
