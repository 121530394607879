var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { create } from 'zustand';
/**
 * Zustand store to manage user profile data.
 * This store includes functionality for setting, resetting, and fetching user profile data,
 * as well as validating date formats.
 */
export var useProfileDataStore = create(function (set) { return ({
    profileObj: {}, // Initial empty profile object
    /**
     * Updates the `profileObj` state with new profile data.
     * @param {Record<string, any>} profile - The new profile data object.
     */
    setProfile: function (profile) {
        return set(function () { return ({
            profileObj: profile,
        }); });
    },
    /**
     * Resets the profile object to an empty state.
     */
    resetDataObj: function () { return set({ profileObj: {} }); },
    /**
     * Fetches the user's profile data from the API.
     * Retrieves the access token from local storage and makes a request to the backend.
     * If successful, updates `profileObj` with the retrieved data.
     * If the token is missing or the request fails, it logs an error message.
     */
    fetchProfile: function () { return __awaiter(void 0, void 0, void 0, function () {
        var token, response, responseData, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem('accessToken');
                    if (!token) {
                        console.log('No access token found. Please log in.');
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch("".concat(process.env.REACT_APP_API_URL, "/user/profile"), {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: "Bearer ".concat(token),
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (!response.ok) {
                        throw new Error('Failed to fetch profile.');
                    }
                    return [4 /*yield*/, response.json()];
                case 3:
                    responseData = _a.sent();
                    set({ profileObj: responseData }); // Update state with fetched profile data
                    return [3 /*break*/, 5];
                case 4:
                    error_1 = _a.sent();
                    throw 'Failed to load profile. Please try again.' + error_1;
                case 5: return [2 /*return*/];
            }
        });
    }); },
    /**
     * Validates and formats a given timestamp string into a human-readable date.
     * @param {string} time - The timestamp string to be formatted.
     * @returns {string | undefined} - Formatted date in "day month year" format (e.g., "15 October 2023"),
     * or "Invalid Date" if the input is not a valid date.
     */
    validTime: function (time) {
        var createdAtDate = new Date(time);
        if (!isNaN(createdAtDate.getTime())) {
            var formattedDate = new Intl.DateTimeFormat('en-GB', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            }).format(createdAtDate);
            return formattedDate;
        }
        else {
            console.error('Invalid date:', time);
            return 'Invalid Date';
        }
    },
}); });
