export var ch_locale = {
    next_btn: 'Další',
    have_an_account: 'Máte již účet?',
    log_in: 'Přihlásit se',
    sign_up: "Přihlásit se / Zaregistrovat se",
    auth_title1: 'Vítejte',
    auth_title2: 'v Quanta',
    line_text: 'nebo',
    sign_up_with_google: 'Zaregistrovat se přes Google',
    sign_up_with_apple: 'Zaregistrovat se přes Apple',
    log_in_title: 'Vítejte zpět',
    email_phone_input_label: 'Email/Telefonní číslo',
    password_input_label: 'Heslo',
    didt_remember_password: 'Nepamatujete si heslo?',
    change_password_title: 'Nové heslo',
    password_check_input_label: 'Ověřit heslo',
    reset_password_title: 'Obnovit heslo',
    email_input_label: 'Email',
    email_verification_title: 'Ověření emailu',
    email_verification_description: 'Zadejte prosím 6místný ověřovací kód, který byl zaslán na',
    email_verification_description2: 'Pokud nevidíte e-mail ve své doručené poště, zkontrolujte složku se spamem nebo nevyžádanou poštou, protože tam mohl být odfiltrován.',
    email_code_title: 'Emailový kód',
    wait_code: 'Počkejte, než se kód pošle znovu',
    not_get_code: 'Neobdrželi jste kód?',
    phone_verification_title: 'Ověření telefonu',
    phone_verification_text1: 'Zadejte prosím 6místný ověřovací kód',
    phone_verification_text2: 'který byl zaslán na vaše telefonní číslo',
    input_phone_label: 'Telefon',
    input_code_label: 'Kód',
    creating_account_title: 'Vytvoření účtu',
    terms_and_policy1: 'Vytvořením účtu',
    terms_and_policy2: ', souhlasím s',
    terms_and_policy3: ' podmínkami používání',
    terms_and_policy4: ' a',
    terms_and_policy5: ' Zásadami ochrany osobních údajů',
    email_verification_code1: 'Zadejte prosím 6místný ověřovací kód',
    email_verification_code2: 'který byl odeslán na',
    email_verification_code3: 'Kód je platný po dobu 30 minut.',
    email_verification_label: 'Ověřovací kód e-mailem',
    home_adress_title: 'Adresa bydliště',
    home_adress_description: 'Ujistěte se, že se jedná o vaši aktuální adresu.',
    full_adress_label: 'Celá adresa',
    postal_code_label: 'PSČ',
    city_label: 'Město',
    region_label: 'Kraj',
    password_creation_title: 'Heslo',
    password_check_label: 'Zkontrolujte heslo',
    personal_information_title: 'Osobní údaje',
    personal_information_description1: 'Zadejte prosím následující údaje tak, jak jsou uvedeny',
    personal_information_description2: 'na vašem pasu nebo občanském průkazu.',
    full_name_label: 'Celé jméno',
    data_about_user: 'Údaje musí odpovídat údajům v pase nebo občanském průkazu.',
    date_birth_label: 'Datum narození',
    phone_verification_code_label: 'Ověřovací kód telefonu',
    residensy_title: 'Pojďme vás ověřit',
    residensy_title_description: 'Nejprve vyberte vaši zemi pobytu.',
    residency_text: 'Ujistěte se, že vaše země pobytu je správná.',
    residency_option: "Bydliště",
    citizenship_option: "Státní příslušnost",
    back_btn_text: "Zpět",
    document_select_title: 'Ověření dokumentu',
    document_select_description: 'Vyberte zemi/region vydání vašeho dokumentu.',
    document_select_warning: 'Použijte platný vládou vydaný dokument',
    document_select_text1: 'Pouze níže uvedené dokumenty ',
    document_select_text2: 'budou přijaty; všechny ostatní dokumenty budou ',
    document_select_text3: 'zamítnuty.',
    id_card_text: 'Občanský průkaz',
    passport_text: 'Cestovní pas',
    kyc_info_title: 'Ověření dokumentu',
    kyc_info_description1: 'Vyfotografujte obě strany vašeho vládou vydaného ',
    kyc_info_description2: 'občanského průkazu.',
    kyc_info_description3: 'pas',
    reqiurements_kyc1: 'Dokument není prošlý',
    reqiurements_kyc2: 'Jedná se o originální dokument, nikoli kopii nebo sken',
    reqiurements_kyc3: 'Odstraňte všechny držáky nebo kryty karet, aby se zabránilo odleskům nebo rozmazání',
    reqiurements_kyc4: 'Položte dokument na pozadí jednobarevné barvy',
    reqiurements_kyc5: 'Dokument by měl být držen rovně, ne nakloněn nebo otočen.',
    picture_card_text: 'Přetáhněte sem svou fotografii nebo vyberte',
    picture_card_signed_text: 'Klikněte pro procházení',
    picture_box_front: 'Přední strana občanského průkazu',
    picture_box_back: 'Zadní strana občanského průkazu',
    picture_passport: 'Obrázek pasu',
    kyc_person_label: 'Ověření osobnosti',
    kyc_person_description: 'Vyfotografujte sebe a svůj dokument ve svých rukou.',
    photo_label: 'Vaše fotografie',
    person_requirements1: 'Obrázek musí být ostrý, bez jakéhokoli rozmazání nebo pixelace.',
    person_requirements2: 'Obličej uživatele i dokument musí být zcela viditelné a ne oříznuté.',
    person_requirements3: 'Informace na dokumentu musí být jasně viditelné a nesmí být zakryty prsty.',
    person_requirements4: 'Dokument by měl být držen rovně, ne nakloněn nebo otočen.',
    verify_btn: 'Ověřit',
    verify_main_text: 'Nyní ověřme vaši identitu, abyste mohli využívat všechny funkce Quanta.',
    acccount_created_title: 'účet vytvořen',
    account_verified_title: 'účet ověřen',
    registartion_success_title: 'Registrace úspěšná',
    auth_success_title: 'Autorizace úspěšná',
    auth_login: 'Nyní používejte všechny funkce Quanta',
    kyc_text: 'O výsledcích budete informováni později.',
    kyc_title: 'KYC odesláno k ověření',
    not_now: 'Teď ne',
    password_requirements1: "Alespoň 8 znaků",
    password_requirements2: "Alespoň 1 číslo",
    password_requirements3: "Alespoň 1 velké písmeno",
    password_requirements4: "Hesla se musí shodovat",
    apple_btn: "Zaregistrovat se přes Apple",
    adress_palceholder: "Adresa",
    first_name_placeholder: "Jméno",
    last_name_placeholder: "Příjmení",
    invalid_file_type: 'Neplatný typ souboru. Nahrajte obrázek ve formátu PNG, JPEG nebo JPG.',
    incorrect_code: 'Zadaný kód je nesprávný. Zkuste to znovu.',
    upload_file: 'Nahrajte prosím soubor.',
    missing_token: 'Chybí autorizační token.',
    picture_missing: 'Chybí obrázek přední nebo zadní strany dokumentu.',
    document_type: 'Chybí typ dokumentu nebo země.',
    existing_document: 'Dokument již existuje pro tohoto uživatele.',
    failed_kyc: 'Nepodařilo se odeslat údaje KYC.',
    unexpected_error: 'Došlo k neočekávané chybě. Zkuste to znovu.',
    password_changed: 'Heslo bylo změněno. Vraťte se na předchozí stránku.',
    failsed_reset_password: 'Nepodařilo se obnovit heslo.',
    error_occured: 'Při obnovování došlo k chybě.',
    login_failed: 'Přihlášení se nezdařilo. Zkuste to znovu.',
    email_send_error: 'Chyba při odesílání kódu ověření e-mailem.',
    parsing_error: 'Došlo k neočekávané chybě při analýze odpovědi.',
    documents_under_review: "Dokumenty jsou již v přezkumu",
    email_already_exist: "E-mail již existuje",
};
