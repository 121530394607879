var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { AuthInput, AuthInputLabel, FormContainer, IconWrapper, InputWrapper, LogInTitle, PassworCreationForm, ValidationList, } from "../../../assets/styles/logIn.style";
import { MainBtnStyle, StyledCol, StyledRow } from "../../../assets/styles/mainPageStyles";
import Tick from '../../../assets/img/tick.svg';
import { Requirements } from '../../../const/validations/passwordRequirements';
import UFOIcon from "../../../components/UFOIcon/UFOIcon";
import { hasCapitalLetter, hasNumber, isLongEnough, isEqual } from "../../../const/validations/validation";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../../store/user/setUser";
import { Alert } from "../../../components/authComponents/Alert";
import TokenService from "../../../utils/tokenService/tokenService";
import { FormattedMessage, useIntl } from "react-intl";
import { BackArrow } from "../../../components/authComponents/BackButton";
export var PasswordCreation = function () {
    // State to toggle password visibility
    var _a = useState(true), isHidePass = _a[0], setIsHidePass = _a[1];
    var _b = useState(true), isHideCheckPass = _b[0], setIsHideCheckPass = _b[1];
    // State for storing user inputted passwords
    var _c = useState(""), password = _c[0], setPassword = _c[1];
    var _d = useState(""), confirmPassword = _d[0], setConfirmPassword = _d[1];
    // Navigation hook to redirect users
    var navigate = useNavigate();
    // User store for accessing user data
    var _e = useUserStore(), userObj = _e.userObj, addProp = _e.addProp;
    // State for handling error messages and alerts
    var _f = useState(null), error = _f[0], setError = _f[1];
    var _g = useState(false), showAlert = _g[0], setShowAlert = _g[1];
    // Internationalization hook for translations
    var intl = useIntl();
    // Function to validate password based on predefined rules
    var validateForm = function () {
        return (hasCapitalLetter(password) &&
            hasNumber(password) &&
            isLongEnough(password) &&
            isEqual(password, confirmPassword));
    };
    // Effect to check if user is authenticated (redirects to login page if email is missing)
    useEffect(function () {
        if (!userObj.email) {
            navigate('/auth');
        }
    }, [userObj, navigate]);
    // Function to send user registration request
    var sendUserRegistration = function (_a) { return __awaiter(void 0, [_a], Promise, function (_b) {
        var response, responseData, error_1;
        var email = _b.email, password = _b.password;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch("".concat(process.env.REACT_APP_API_URL, "/user/auth/registration-with-email"), {
                            method: 'POST',
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({ email: email, password: password }),
                        })];
                case 1:
                    response = _c.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    responseData = _c.sent();
                    if (!response.ok) {
                        setError(responseData.message || "Sign up failed");
                        setShowAlert(true);
                        return [2 /*return*/, false];
                    }
                    // Store tokens in local storage
                    TokenService.setTokens(responseData.access_token, responseData.refresh_token);
                    setError(null);
                    return [2 /*return*/, true];
                case 3:
                    error_1 = _c.sent();
                    setError("unexpected_error");
                    console.error("Error sending request:", error_1);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // Function to handle form submission
    var handleNextClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isSuccessful;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateForm()) return [3 /*break*/, 2];
                    addProp("password", password);
                    return [4 /*yield*/, sendUserRegistration({ email: userObj.email, password: password })];
                case 1:
                    isSuccessful = _a.sent();
                    if (isSuccessful) {
                        // Navigate to success page after successful registration
                        navigate("/success", { state: { from: '/registration/creating-password' } });
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(StyledRow, { "$justifyContent": "center", style: { height: '100%' }, children: _jsx(StyledCol, { style: { padding: 0 }, "$justifyContent": "center", "$xl": 12, "$lg": 12, "$md": 12, "$sm": 12, "$xs": 12, children: _jsxs(StyledCol, { "$justifyContent": "center", "$xl": 12, "$lg": 12, "$md": 12, "$sm": 12, "$xs": 12, children: [_jsx(BackArrow, { position: "absolute", top: "10%", left: "35%", navigationLink: "/registration/email" }), _jsxs(FormContainer, { children: [_jsx(LogInTitle, { children: _jsx(FormattedMessage, { id: "password_creation_title", defaultMessage: "Password" }) }), _jsxs(PassworCreationForm, { onSubmit: function (e) {
                                    e.preventDefault();
                                    handleNextClick();
                                }, children: [_jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: "password_creation_title", defaultMessage: "Password" }) }), _jsxs(InputWrapper, { children: [_jsx(AuthInput, { placeholder: intl.formatMessage({ id: 'password_creation_title', defaultMessage: 'Password' }), type: isHidePass ? 'password' : 'text', value: password, onChange: function (e) { return setPassword(e.target.value); }, role: "input" }), _jsx(IconWrapper, { onClick: function () { return setIsHidePass(!isHidePass); }, children: _jsx(UFOIcon, { isHidePass: isHidePass }) })] }), _jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: "password_check_label", defaultMessage: "Check password" }) }), _jsxs(InputWrapper, { children: [_jsx(AuthInput, { placeholder: intl.formatMessage({ id: 'password_check_label', defaultMessage: 'Check password' }), type: isHideCheckPass ? 'password' : 'text', value: confirmPassword, onChange: function (e) { return setConfirmPassword(e.target.value); }, role: "inputCheck" }), _jsx(IconWrapper, { onClick: function () { return setIsHideCheckPass(!isHideCheckPass); }, children: _jsx(UFOIcon, { isHidePass: isHideCheckPass }) })] }), _jsx(ValidationList, { children: Requirements(password, confirmPassword).map(function (requirement, index) { return (_jsxs("li", { role: "listitem", style: requirement.check() ? { filter: 'grayscale(0)' } : { filter: 'grayscale(99%)', opacity: '50%' }, children: [_jsx("img", { src: Tick, alt: "Validation Icon" }), _jsx(FormattedMessage, { id: requirement.title, defaultMessage: requirement.defaultMessage })] }, index)); }) }), _jsx(MainBtnStyle, { type: "submit", width: "50%", style: {
                                            opacity: validateForm() ? "100%" : "50%",
                                            cursor: validateForm() ? "pointer" : "not-allowed",
                                        }, children: _jsx(FormattedMessage, { id: "next_btn", defaultMessage: "Next" }) })] }), showAlert && error && (_jsx(Alert, { message: error, onClose: function () { return setShowAlert(false); }, isSuccess: false }))] })] }) }) }));
};
