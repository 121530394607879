export var ru_locale = {
    next_btn: 'Далее',
    have_an_account: 'Уже есть аккаунт?',
    log_in: 'Войти',
    sign_up: "Войти / Зарегистрироваться",
    auth_title1: 'Добро пожаловать',
    auth_title2: 'в Quanta',
    line_text: 'или',
    sign_up_with_google: 'Зарегистрироваться через Google',
    sign_up_with_apple: 'Зарегистрироваться через Apple',
    log_in_title: 'С возвращением',
    email_phone_input_label: 'Электронная почта/Телефон',
    password_input_label: 'Пароль',
    didt_remember_password: 'Не помните пароль?',
    change_password_title: 'Новый пароль',
    password_check_input_label: 'Подтвердите пароль',
    reset_password_title: 'Сбросить пароль',
    email_input_label: 'Электронная почта',
    email_verification_title: 'Подтверждение электронной почты',
    email_verification_description: 'Пожалуйста, введите 6-значный код подтверждения, который был отправлен на',
    email_code_title: 'Код электронной почты',
    wait_code: 'Подождите, чтобы отправить код снова',
    not_get_code: 'Не получили код?',
    phone_verification_title: 'Подтверждение телефона',
    phone_verification_text1: 'Пожалуйста, введите 6-значный код подтверждения',
    phone_verification_text2: 'который был отправлен на ваш номер телефона',
    input_phone_label: 'Телефон',
    input_code_label: 'Код',
    email_verification_description2: 'Если вы не видите письмо во входящих, проверьте папку "Спам" или "Нежелательная почта", так как оно могло быть отфильтровано туда.',
    creating_account_title: 'Создание аккаунта',
    terms_and_policy1: 'Создавая аккаунт',
    terms_and_policy2: ', я соглашаюсь с',
    terms_and_policy3: ' условиями ',
    terms_and_policy4: 'Пользовательского соглашения',
    terms_and_policy5: ' и ',
    terms_and_policy6: 'Политикой конфиденциальности',
    email_verification_code1: 'Пожалуйста, введите 6-значный код подтверждения',
    email_verification_code2: 'который был отправлен на',
    email_verification_code3: 'Код действителен в течение 30 минут.',
    email_verification_label: 'Код подтверждения по email',
    home_adress_title: 'Домашний адрес',
    home_adress_description: 'Убедитесь, что это ваш текущий адрес проживания.',
    full_adress_label: 'Полный адрес',
    postal_code_label: 'Почтовый код',
    city_label: 'Город',
    region_label: 'Регион',
    password_creation_title: 'Пароль',
    password_check_label: 'Проверьте пароль',
    personal_information_title: 'Личная информация',
    personal_information_description1: 'Пожалуйста, предоставьте следующую информацию, как указано',
    personal_information_description2: 'в вашем паспорте или удостоверении личности.',
    full_name_label: 'Полное имя',
    data_about_user: 'Данные должны совпадать с данными в паспорте или удостоверении личности.',
    date_birth_label: 'Дата рождения',
    phone_verification_code_label: 'Код подтверждения по телефону',
    residensy_title: 'Давайте вас подтвердим',
    residensy_title_description: 'Сначала выберите страну проживания.',
    residency_text: 'Убедитесь, что ваша страна проживания указана верно.',
    residency_option: "Местожительство",
    citizenship_option: "Гражданство",
    back_btn_text: "Назад",
    document_select_title: 'Проверка документа',
    document_select_description: 'Выберите страну/регион, выдавший документ.',
    document_select_warning: 'Используйте действительный документ, выданный государством',
    document_select_text1: 'Будут приняты только следующие документы, указанные ниже ',
    document_select_text2: 'все остальные документы будут ',
    document_select_text3: 'отклонены.',
    id_card_text: 'Паспорт',
    passport_text: 'Заграничный паспорт',
    kyc_info_title: 'Проверка документа',
    kyc_info_description1: 'Сделайте фото обеих сторон вашего ',
    kyc_info_description2: 'государственного документа.',
    kyc_info_description3: 'паспорт',
    reqiurements_kyc1: 'Документ не просрочен',
    reqiurements_kyc2: 'Это оригинальный документ, не скан или копия',
    reqiurements_kyc3: 'Уберите все держатели или чехлы, чтобы избежать бликов или размытия',
    reqiurements_kyc4: 'Разместите документ на однотонном фоне',
    reqiurements_kyc5: 'Документ должен быть ровным, не наклонённым или повернутым.',
    picture_card_text: 'Перетащите сюда фото или выберите файл',
    picture_card_signed_text: 'Кликните для выбора',
    picture_box_front: 'Лицевая сторона паспорта',
    picture_box_back: 'Обратная сторона паспорта',
    picture_passport: 'Фотография паспорта',
    kyc_person_label: 'Проверка личности',
    kyc_person_description: 'Сделайте фото себя с документом в руках.',
    photo_label: 'Ваше фото',
    person_requirements1: 'Изображение должно быть чётким, без размытий или пикселизации.',
    person_requirements2: 'Лицо пользователя и документ должны быть полностью видны и не обрезаны.',
    person_requirements3: 'Информация на документе должна быть чётко видна и не прикрыта пальцами.',
    person_requirements4: 'Документ должен быть ровным, не наклонённым или повернутым.',
    verify_btn: 'Подтвердить',
    verify_main_text: 'Теперь давайте подтвердим вашу личность, чтобы использовать все функции Quanta.',
    acccount_created_title: 'аккаунт создан',
    account_verified_title: 'аккаунт подтверждён',
    registartion_success_title: 'Регистрация успешна',
    auth_success_title: 'Авторизация успешна',
    auth_login: 'Теперь используйте все функции Quanta',
    kyc_text: 'Вас уведомят о результатах позже.',
    kyc_title: 'KYC отправлен на проверку',
    not_now: 'Не сейчас',
    password_requirements1: "Не менее 8 символов",
    password_requirements2: "Не менее 1 цифры",
    password_requirements3: "Не менее 1 заглавной буквы",
    password_requirements4: "Пароли должны совпадать",
    apple_btn: "Зарегистрироваться через Apple",
    adress_palceholder: "Адрес",
    first_name_placeholder: "Имя",
    last_name_placeholder: "Фамилия",
    invalid_file_type: 'Недопустимый тип файла. Пожалуйста, загрузите изображение в формате PNG, JPEG или JPG.',
    incorrect_code: 'Введённый код неверен. Пожалуйста, попробуйте снова.',
    upload_file: 'Пожалуйста, загрузите файл.',
    missing_token: 'Отсутствует токен авторизации.',
    picture_missing: 'Отсутствует изображение документа (лицевая или обратная сторона).',
    document_type: 'Тип документа или страна отсутствуют.',
    existing_document: 'Документ уже существует для этого пользователя.',
    failed_kyc: 'Не удалось отправить данные KYC.',
    unexpected_error: 'Произошла непредвиденная ошибка. Пожалуйста, попробуйте позже.',
    password_changed: 'Пароль изменён. Вернитесь на предыдущую страницу.',
    failsed_reset_password: 'Не удалось сбросить пароль.',
    error_occured: 'Произошла ошибка во время сброса.',
    login_failed: 'Не удалось войти, пожалуйста, попробуйте снова.',
    email_send_error: 'Ошибка при отправке кода подтверждения на электронную почту.',
    parsing_error: 'Произошла непредвиденная ошибка при обработке ответа.',
    documents_under_review: "Документы уже на рассмотрении",
    email_already_exist: "Электронная почта уже существует",
};
