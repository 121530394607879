export var he_locale = {
    next_btn: 'הבא',
    have_an_account: 'כבר יש לך חשבון?',
    log_in: 'התחבר',
    sign_up: "התחברות / הרשמה",
    auth_title1: 'ברוך הבא',
    auth_title2: 'ל-Quanta',
    line_text: 'או',
    sign_up_with_google: 'הירשם עם Google',
    sign_up_with_apple: 'הירשם עם Apple',
    log_in_title: 'ברוך הבא שוב',
    email_phone_input_label: 'אימייל/מספר טלפון',
    password_input_label: 'סיסמה',
    didt_remember_password: 'לא זוכר סיסמה?',
    change_password_title: 'סיסמה חדשה',
    password_check_input_label: 'בדוק סיסמה',
    reset_password_title: 'איפוס סיסמה',
    email_input_label: 'אימייל',
    email_verification_title: 'אימות אימייל',
    email_verification_description: 'אנא הזן את קוד האימות בן 6 הספרות שנשלח ל',
    email_verification_description2: 'אם אינך רואה את האימייל בתיבת הדואר הנכנס, בדוק את תיקיית הספאם או הדואר הזבל, ייתכן שהוא סונן לשם.',
    email_code_title: 'קוד אימייל',
    wait_code: 'חכה לשלוח קוד מחדש',
    not_get_code: 'לא קיבלת קוד?',
    phone_verification_title: 'אימות טלפון',
    phone_verification_text1: 'אנא הזן את קוד האימות בן 6 הספרות',
    phone_verification_text2: 'שנשלח למספר הטלפון שלך',
    input_phone_label: 'טלפון',
    input_code_label: 'קוד',
    creating_account_title: 'יצירת חשבון',
    terms_and_policy1: 'על ידי יצירת חשבון',
    terms_and_policy2: ', אני מסכים ל',
    terms_and_policy3: ' תנאי השימוש',
    terms_and_policy4: ' ו',
    terms_and_policy5: 'מדיניות פרטיות',
    email_verification_code1: 'אנא הזן את קוד האימות בן 6 הספרות',
    email_verification_code2: 'שנשלח ל',
    email_verification_code3: 'הקוד תקף במשך 30 דקות.',
    email_verification_label: 'קוד אימות אימייל',
    home_adress_title: 'כתובת מגורים',
    home_adress_description: 'וודא שזו כתובת המגורים הנוכחית שלך.',
    full_adress_label: 'כתובת מלאה',
    postal_code_label: 'מיקוד',
    city_label: 'עיר',
    region_label: 'אזור',
    password_creation_title: 'סיסמה',
    password_check_label: 'בדוק סיסמה',
    personal_information_title: 'מידע אישי',
    personal_information_description1: 'אנא ספק את המידע הבא כפי שמופיע',
    personal_information_description2: 'בדרכון או בתעודת זהות שלך.',
    full_name_label: 'שם מלא',
    data_about_user: 'הנתונים חייבים להתאים לנתונים בדרכון או בתעודת זהות.',
    date_birth_label: 'תאריך לידה',
    phone_verification_code_label: 'קוד אימות טלפון',
    residensy_title: 'בוא נוודא את זה',
    residensy_title_description: 'קודם כל, בחר את המדינה בה אתה גר.',
    residency_text: 'וודא שכתובת המגורים שלך נכונה.',
    residency_option: "מגורים",
    citizenship_option: "אזרחות",
    back_btn_text: "חזרה",
    document_select_title: 'אימות מסמך',
    document_select_description: 'בחר את המדינה/האזור שהנפיק את המסמך.',
    document_select_warning: 'השתמש במסמך תקף שהונפק על ידי הממשלה',
    document_select_text1: 'רק המסמכים הבאים המופיעים למטה ',
    document_select_text2: 'יתקבלו; כל המסמכים האחרים יידחו ',
    document_select_text3: '.',
    id_card_text: 'תעודת זהות',
    passport_text: 'דרכון',
    kyc_info_title: 'אימות מסמך',
    kyc_info_description1: 'צלם תמונה של שני צידי המסמך שהונפק ',
    kyc_info_description2: 'על ידי הממשלה.',
    kyc_info_description3: 'דרכון',
    reqiurements_kyc1: 'המסמך אינו פג תוקף',
    reqiurements_kyc2: 'זהו מסמך מקורי, לא סריקה או עותק',
    reqiurements_kyc3: 'הסר כיסויי מסמכים או כיסויים למניעת השתקפויות או טשטוש',
    reqiurements_kyc4: 'מקם את המסמך על רקע בצבע אחיד',
    reqiurements_kyc5: 'יש להחזיק את המסמך ישר, לא מוטה או מסובב.',
    picture_card_text: 'גרור את התמונה שלך כאן או בחר',
    picture_card_signed_text: 'לחץ כדי לעיין',
    picture_box_front: 'הצד הקדמי של תעודת הזהות',
    picture_box_back: 'הצד האחורי של תעודת הזהות',
    picture_passport: 'תמונה של הדרכון',
    kyc_person_label: 'אימות זהות',
    kyc_person_description: 'צלם תמונה שלך עם המסמך בידיים שלך.',
    photo_label: 'תמונה שלך',
    person_requirements1: 'התמונה חייבת להיות חדה, ללא טשטוש או פיקסול.',
    person_requirements2: 'פני המשתמש והמסמך חייבים להיות גלויים במלואם ולא חתוכים.',
    person_requirements3: 'המידע על המסמך צריך להיות ברור ולא מכוסה באצבעות.',
    person_requirements4: 'יש להחזיק את המסמך ישר, לא מוטה או מסובב.',
    verify_btn: 'אמת',
    verify_main_text: 'בואו נאמת את הזהות שלך כדי שתוכל להשתמש בכל התכונות של Quanta.',
    acccount_created_title: 'חשבון נוצר',
    account_verified_title: 'חשבון אומת',
    registartion_success_title: 'הרשמה הצליחה',
    auth_success_title: 'אימות הצליח',
    auth_login: 'עכשיו תוכל להשתמש בכל התכונות של Quanta',
    kyc_text: 'תקבל הודעה על התוצאות מאוחר יותר.',
    kyc_title: 'KYC נשלח לבדיקה',
    not_now: 'לא עכשיו',
    password_requirements1: "לפחות 8 תווים",
    password_requirements2: "לפחות מספר אחד",
    password_requirements3: "לפחות אות גדולה אחת",
    password_requirements4: "הסיסמאות חייבות להתאים",
    apple_btn: "הרשמה עם Apple",
    adress_palceholder: "כתובת",
    first_name_placeholder: "שם פרטי",
    last_name_placeholder: "שם משפחה",
    invalid_file_type: 'סוג קובץ לא תקף. אנא העלה תמונה בפורמט PNG, JPEG או JPG.',
    incorrect_code: 'הקוד שהוזן שגוי. אנא נסה שוב.',
    upload_file: 'אנא העלה קובץ.',
    missing_token: 'חסר אסימון אימות.',
    picture_missing: 'חסרה תמונה מקדימה או אחורית של המסמך.',
    document_type: 'חסר סוג מסמך או מדינה.',
    existing_document: 'כבר קיים מסמך למשתמש זה.',
    failed_kyc: 'לא הצליח להגיש את פרטי ה-KYC.',
    unexpected_error: 'אירעה שגיאה בלתי צפויה. אנא נסה שוב.',
    password_changed: 'הסיסמה שונתה. חזור לדף הקודם.',
    failsed_reset_password: 'נכשל בהגדרת הסיסמה מחדש.',
    error_occured: 'אירעה שגיאה במהלך האיפוס.',
    login_failed: 'ההתחברות נכשלה, אנא נסה שוב.',
    email_send_error: 'שגיאה בהשליחת קוד האימות בדוא"ל.',
    parsing_error: 'אירעה שגיאה בלתי צפויה בעת ניתוח התגובה.',
    documents_under_review: "המסמכים כבר בבדיקה",
    email_already_exist: "הדוא\"ל כבר קיים",
};
