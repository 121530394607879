import { useState } from "react";
/**
 * Custom React hook for handling file uploads.
 * It manages selected files, generates temporary URLs for previews, and validates file types.
 *
 * @param {string[]} acceptedFormats - Array of accepted MIME types (e.g., ["image/png", "image/jpeg"]).
 * @returns {{
 *   files: File[];
 *   fileURLs: Record<string, string>;
 *   error: string | null;
 *   handleFileChange: (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => void;
 *   clearError: () => void;
 * }} - Returns the selected files, preview URLs, error state, file change handler, and error reset function.
 */
export var useFileUpload = function (acceptedFormats) {
    var _a = useState([]), files = _a[0], setFiles = _a[1]; // Stores selected files
    var _b = useState({}), fileURLs = _b[0], setFileURLs = _b[1]; // Stores object URLs for preview
    var _c = useState(null), error = _c[0], setError = _c[1]; // Stores validation error message
    /**
     * Handles file input changes, validates file types, and generates preview URLs.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e - File input change event.
     * @param {string} fieldName - Name of the input field (not used in current implementation).
     */
    var handleFileChange = function (e, fieldName) {
        var newFiles = e.target.files ? Array.from(e.target.files) : []; // Convert FileList to an array
        var invalidFiles = newFiles.filter(function (file) { return !acceptedFormats.includes(file.type); }); // Filter out invalid files
        if (invalidFiles.length > 0) {
            setError("invalid_file_type"); // Set error if invalid file type is detected
            return;
        }
        setFiles(newFiles); // Update state with valid files
        // Generate object URLs for previewing selected files
        var newFileURLs = newFiles.reduce(function (acc, file) {
            acc[file.name] = URL.createObjectURL(file);
            return acc;
        }, {});
        setFileURLs(newFileURLs); // Update state with new preview URLs
    };
    /**
     * Clears any existing error messages.
     */
    var clearError = function () { return setError(null); };
    return { files: files, fileURLs: fileURLs, error: error, handleFileChange: handleFileChange, clearError: clearError };
};
