var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AuthInput, AuthInputLabel, FormContainer, FormContainerText, InputWrapper, LogInTitle, } from "../../../assets/styles/logIn.style";
import { MainBtnStyle, StyledCol, StyledRow, PositionLayout } from "../../../assets/styles/mainPageStyles";
import React, { useState } from "react";
import { BackArrow } from "../../../components/authComponents/BackButton";
import validator from "validator";
import { Alert } from "../../../components/authComponents/Alert";
import { FormattedMessage, useIntl } from "react-intl";
/**
 * Component for handling password reset via email.
 * Users enter their email to receive a password reset link.
 */
export var ChangePasswordEmail = function () {
    // State for storing the email input value
    var _a = useState(""), email = _a[0], setEmail = _a[1];
    // State for showing/hiding the alert message
    var _b = useState(false), showAlert = _b[0], setShowAlert = _b[1];
    // State for storing error messages
    var _c = useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    // State to track whether the operation was successful
    var _d = useState(false), isSuccess = _d[0], setIsSuccess = _d[1];
    // Hook for handling internationalization
    var intl = useIntl();
    /**
     * Sends a request to the backend to initiate a password reset process.
     */
    var sendPasswordChange = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
        var response, responseData, error_1;
        var email = _b.email;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch("".concat(process.env.REACT_APP_API_URL, "/user/auth/forgot-password"), {
                            method: 'POST',
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({ email: email }),
                        })];
                case 1:
                    response = _c.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    responseData = _c.sent();
                    console.log(responseData);
                    return [2 /*return*/, response];
                case 3:
                    error_1 = _c.sent();
                    console.error("Error sending email request:", error_1);
                    throw error_1;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    /**
     * Handles form submission for the password reset request.
     * Validates the email and calls the API to send a reset link.
     */
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, sendPasswordChange({ email: email })];
                case 2:
                    response = _a.sent();
                    // Check if the request was successful
                    if (response && response.ok) {
                        setIsSuccess(true);
                        setErrorMessage("Password reset link sent to your email.");
                    }
                    else {
                        setIsSuccess(false);
                        setErrorMessage("Failed to send reset link. Please try again.");
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    setIsSuccess(false);
                    setErrorMessage("Error occurred. Please try again.");
                    return [3 /*break*/, 4];
                case 4:
                    setShowAlert(true);
                    return [2 /*return*/];
            }
        });
    }); };
    /**
     * Validates the email input using a third-party validator library.
     */
    var validateForm = function () {
        return validator.isEmail(email);
    };
    return (_jsxs(StyledRow, { "$justifyContent": "center", style: { height: '100%' }, children: [_jsxs(StyledCol, { "$lg": 7, "$md": 12, children: [_jsx(StyledCol, { "$justifyContent": "center", "$alignItems": "start", "$lg": 2, "$md": 1, "$sm": 1, "$xs": 1, children: _jsx(BackArrow, {}) }), _jsx(StyledCol, { "$justifyContent": "center", "$lg": 8, "$md": 9, "$sm": 9, "$xs": 9, children: _jsxs(FormContainer, { children: [_jsx(FormContainerText, { children: _jsx(LogInTitle, { children: _jsx(FormattedMessage, { id: "reset_password_title" }) }) }), _jsxs(PositionLayout, { as: "form", display: "flex", flexDirection: "column", width: "85%", onSubmit: handleSubmit, children: [_jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: "email_input_label" }) }), _jsx(InputWrapper, { children: _jsx(AuthInput, { placeholder: intl.formatMessage({ id: 'email_input_label' }), value: email, onChange: function (e) { return setEmail(e.target.value); }, "data-testid": 'password-reset' }) }), _jsx(MainBtnStyle, { type: "submit", width: "50%", disabled: !validateForm(), style: {
                                                marginTop: '8px',
                                                opacity: validateForm() ? "100%" : "50%",
                                                cursor: validateForm() ? "pointer" : "not-allowed",
                                            }, children: _jsx(FormattedMessage, { id: "next_btn" }) })] })] }) })] }), showAlert && (_jsx(_Fragment, { children: _jsx(Alert, { message: errorMessage, onClose: function () { return setShowAlert(false); }, isSuccess: isSuccess, "data-testid": "alert-message" }) }))] }));
};
