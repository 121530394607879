var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from "react";
import { LogInContainer, LogInTitle, SpaceBackground, VerifyText } from "../../../assets/styles/logIn.style";
import { MainBtnStyle, StyledCol, StyledRow } from "../../../assets/styles/mainPageStyles";
import { Box, BoxContainer, ContainerKYCBtn, FormContainer, HiddenInput, Instructions, UploadBox, UploadSection } from "../../../assets/styles/kycStyle";
import { BackArrow } from "../../../components/authComponents/BackButton";
import ImgIcon from "../../../assets/img/Img-icon.svg";
import { useNavigate } from "react-router-dom";
import { useKYCStore } from "../../../store/user/setUserKYC";
import { Alert } from "../../../components/authComponents/Alert";
import { useFileUpload } from "../../../store/user/useFileUpload";
import { FormattedMessage } from "react-intl";
import { personInfo } from "../../../const/kycRequirements/kycInfo";
export var KYCPerson = function () {
    // Reference to the hidden file input field
    var personInputRef = useRef(null);
    // Hook for navigation
    var navigate = useNavigate();
    // Zustand store for KYC user data
    var _a = useKYCStore(), userKYCObj = _a.userKYCObj, addData = _a.addData;
    // Hook for file uploads (with allowed file formats)
    var _b = useFileUpload(["image/png", "image/jpeg", "image/jpg"]), error = _b.error, handleFileChange = _b.handleFileChange, clearError = _b.clearError, files = _b.files, fileURLs = _b.fileURLs;
    // State for handling error messages
    var _c = useState(""), isError = _c[0], setError = _c[1];
    // Redirects user if the front document image is not uploaded
    useEffect(function () {
        if (!userKYCObj.docImageFront) {
            navigate("/kyc-doc");
        }
    }, [userKYCObj, navigate]);
    // Triggers file input when clicking the upload box
    var handleFrontUploadClick = function () { var _a; return (_a = personInputRef.current) === null || _a === void 0 ? void 0 : _a.click(); };
    // Validates if the form has at least one uploaded file
    var validateForm = function () { return files.length > 0; };
    // Handles form submission for KYC verification
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var token, toBase64, personImageBase64, payload, response, responseData, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    console.log(userKYCObj);
                    // If no file is uploaded, show an error
                    if (files.length === 0) {
                        setError("upload_file");
                    }
                    token = localStorage.getItem("accessToken");
                    if (!token) {
                        setError("missing_token");
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    toBase64 = function (file) {
                        return new Promise(function (resolve, reject) {
                            var reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = function () { return resolve(reader.result); };
                            reader.onerror = function (error) { return reject(error); };
                        });
                    };
                    return [4 /*yield*/, toBase64(files[0])];
                case 2:
                    personImageBase64 = _a.sent();
                    // Saves the uploaded image to the store
                    addData("personImage", personImageBase64);
                    // Validates that the document type and country are selected
                    if (!userKYCObj.document || !userKYCObj.documentCountry) {
                        setError("document_type");
                        return [2 /*return*/];
                    }
                    payload = {
                        personImage: personImageBase64,
                        docImageFront: userKYCObj.docImageFront,
                        document: userKYCObj.document,
                        documentCountry: userKYCObj.documentCountry,
                        docImageBack: userKYCObj.document !== "passport" ? userKYCObj.docImageBack : "",
                    };
                    return [4 /*yield*/, fetch("".concat(process.env.REACT_APP_API_URL, "/user/add-user-document"), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer ".concat(token),
                            },
                            body: JSON.stringify(payload),
                        })];
                case 3:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 4:
                    responseData = _a.sent();
                    console.log("Response Status:", response.status);
                    // Handles duplicate document error
                    if (response.status == 409) {
                        setError("existing_document");
                        console.log(responseData);
                    }
                    // If request fails, display an error message
                    if (!response.ok) {
                        throw new Error(responseData.message || "failed_kyc");
                    }
                    // Redirects user to the success page
                    navigate("/success", { state: { from: "/kyc-person" } });
                    return [3 /*break*/, 6];
                case 5:
                    err_1 = _a.sent();
                    if (err_1 instanceof Error) {
                        console.error("Error submitting user details:", err_1.message);
                        setError(err_1.message);
                    }
                    else {
                        console.error("Unexpected error:", err_1);
                        setError("unexpected_error");
                    }
                    return [3 /*break*/, 6];
                case 6:
                    navigate("/success", { state: { from: "/kyc-person" } });
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(SpaceBackground, { children: [_jsx(LogInContainer, { children: _jsx(StyledRow, { "$justifyContent": "center", style: { height: "100%" }, children: _jsx(StyledCol, { "$justifyContent": "center", "$lg": 7, "$md": 12, children: _jsxs(FormContainer, { onSubmit: handleSubmit, style: { justifyContent: "start", alignItems: "start" }, children: [_jsx(LogInTitle, { children: _jsx(FormattedMessage, { id: "kyc_person_label" }) }), _jsx(VerifyText, { children: _jsx(FormattedMessage, { id: "kyc_person_description" }) }), _jsx(UploadSection, { children: _jsxs(Box, { style: { justifyContent: "start", alignItems: "start" }, children: [_jsx("span", { children: _jsx(FormattedMessage, { id: "photo_label" }) }), _jsxs(UploadBox, { onClick: handleFrontUploadClick, image: Object.values(fileURLs)[0] || "", children: [files.length === 0 && (_jsxs(BoxContainer, { children: [_jsx("img", { src: ImgIcon, alt: "img-icon" }), _jsxs("span", { style: { fontSize: "12px" }, children: [_jsx(FormattedMessage, { id: "picture_card_text" }), _jsx("br", {}), _jsx("span", { style: { color: "#FFB801", fontSize: "12px" }, children: _jsx(FormattedMessage, { id: "picture_card_signed_text" }) })] })] })), _jsx(HiddenInput, { type: "file", accept: "image/png, image/jpeg, image/jpg", ref: personInputRef, onChange: function (e) { return handleFileChange(e, "personImage"); }, multiple: true })] })] }) }), _jsx(Instructions, { children: personInfo.map(function (_a) {
                                        var messageId = _a.messageId, defaultMessage = _a.defaultMessage;
                                        return (_jsx("li", { children: _jsx(FormattedMessage, { id: messageId, defaultMessage: defaultMessage }) }, messageId));
                                    }) }), _jsxs(ContainerKYCBtn, { children: [_jsx(BackArrow, {}), _jsx(MainBtnStyle, { type: "submit", width: "50%", style: {
                                                opacity: validateForm() ? "100%" : "50%",
                                                cursor: validateForm() ? "pointer" : "not-allowed",
                                            }, children: _jsx(FormattedMessage, { id: "next_btn" }) })] })] }) }) }) }), error || isError && _jsx(Alert, { message: error || isError, onClose: clearError, isSuccess: false })] }));
};
