var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { startTransition, Suspense, useEffect, useMemo, useState } from "react";
import CanvasScene from "../components/globe/CanvasScene";
import { useLocation } from "react-router-dom";
import { useLineaEquation } from "../hooks/planetBlockHooks";
import LeftImgCircle from '../assets/img/left-circles.svg';
import { PositionLayout } from '../assets/styles/mainPageStyles';
import BottomImgTunnels from '../assets/img/bottom-tunnel.svg';
var AuthGlobeLayout = function () {
    var location = useLocation();
    // Отдельный стейт для хранения позиции Canvas
    var _a = useState({ x: 0, y: 0, z: -3 }), canvasPosition = _a[0], setCanvasPosition = _a[1];
    // Хук, который следит за изменением размеров окна
    useEffect(function () {
        function updateCanvasPosition() {
            var width = window.innerWidth;
            var newX = 0;
            var newZ = -3;
            var newY = 0;
            if (width > 1440) {
                newX = 7;
            }
            else if (width > 840) {
                newX = 5;
            }
            else if (width > 720) {
                newX = 4;
            }
            else if (width > 520) {
                newX = 2;
            }
            else {
                newX = 1;
                newZ = -9;
                newY = 2;
            }
            setCanvasPosition(function (prev) { return (__assign(__assign({}, prev), { x: newX, z: newZ, y: newY })); });
        }
        updateCanvasPosition();
        window.addEventListener("resize", updateCanvasPosition);
        return function () { return window.removeEventListener("resize", updateCanvasPosition); };
    }, []);
    // Определяем шаг в зависимости от URL
    var authStepHandler = function () { return (location.pathname === "/auth/login" ? 1 : 2); };
    // Пример исходных данных для формирования Y
    var positionNumbers = [1, 2];
    var yPoints = [-2.3, -1];
    var _b = useState(authStepHandler()), step = _b[0], setStep = _b[1];
    var planetYPos = useLineaEquation({
        xPoints: positionNumbers,
        yPoints: yPoints
    });
    // Запоминаем Y для текущего шага (1 или 2)
    var memoizedYPos = useMemo(function () { return planetYPos(step); }, [step, planetYPos]);
    // Следим за изменением url, чтобы вычислять новый step
    useEffect(function () {
        startTransition(function () {
            setStep(authStepHandler());
        });
    }, [location.pathname]);
    // const memoizedYPos = useMemo(() => planetYPos(step), [step]);
    useEffect(function () {
        startTransition(function () {
            setStep(authStepHandler());
        });
    }, [location.pathname]);
    return (_jsxs("div", { style: {
            position: "absolute",
            bottom: 0,
            right: 0,
            height: "100%",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }, children: [_jsx(Suspense, { fallback: _jsx("div", { children: "Loading Canvas..." }), children: _jsx(CanvasScene, { x: canvasPosition.x, y: memoizedYPos + canvasPosition.y, z: canvasPosition.z, color: 0x3B1049 }) }), _jsx(PositionLayout, { position: 'absolute', left: '0', topXl: '15%', topLg: '10%', children: _jsx("img", { src: LeftImgCircle }) }), _jsx(PositionLayout, { position: 'absolute', bottom: '0', left: '30%', children: _jsx("img", { src: BottomImgTunnels }) })] }));
};
export default AuthGlobeLayout;
