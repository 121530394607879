export var ar_locale = {
    next_btn: 'التالي',
    have_an_account: 'هل لديك حساب؟',
    log_in: 'تسجيل الدخول',
    sign_up: "تسجيل الدخول / الاشتراك",
    auth_title1: 'مرحبًا',
    auth_title2: 'بـ Quanta',
    line_text: 'أو',
    sign_up_with_google: 'سجل باستخدام Google',
    sign_up_with_apple: 'سجل باستخدام Apple',
    log_in_title: 'مرحبًا بعودتك',
    email_phone_input_label: 'البريد الإلكتروني/رقم الهاتف',
    password_input_label: 'كلمة المرور',
    didt_remember_password: 'هل نسيت كلمة المرور؟',
    change_password_title: 'كلمة مرور جديدة',
    password_check_input_label: 'تحقق من كلمة المرور',
    reset_password_title: 'إعادة تعيين كلمة المرور',
    email_input_label: 'البريد الإلكتروني',
    email_verification_title: 'التحقق من البريد الإلكتروني',
    email_verification_description: 'يرجى إدخال رمز التحقق المكون من 6 أرقام الذي تم إرساله إلى',
    email_verification_description2: 'إذا لم تجد البريد الإلكتروني في صندوق الوارد الخاص بك، يرجى التحقق من مجلد البريد العشوائي أو غير المرغوب فيه، فقد يكون قد تم تصفيته هناك.',
    email_code_title: 'رمز البريد الإلكتروني',
    wait_code: 'انتظر لإعادة إرسال الرمز',
    not_get_code: 'لم تحصل على الرمز؟',
    phone_verification_title: 'التحقق من الهاتف',
    phone_verification_text1: 'يرجى إدخال رمز التحقق المكون من 6 أرقام',
    phone_verification_text2: 'الذي تم إرساله إلى رقم هاتفك',
    input_phone_label: 'الهاتف',
    input_code_label: 'الرمز',
    creating_account_title: 'إنشاء حساب',
    terms_and_policy1: 'بإنشاء حساب',
    terms_and_policy2: ', أوافق على',
    terms_and_policy3: ' شروط الاستخدام',
    terms_and_policy4: ' و',
    terms_and_policy5: ' سياسة الخصوصية',
    email_verification_code1: 'الرجاء إدخال رمز التحقق المكون من 6 أرقام',
    email_verification_code2: 'الذي تم إرساله إلى',
    email_verification_code3: 'الرمز صالح لمدة 30 دقيقة.',
    email_verification_label: 'رمز التحقق من البريد الإلكتروني',
    home_adress_title: 'عنوان المنزل',
    home_adress_description: 'تأكد من أن هذا هو عنوانك الحالي.',
    full_adress_label: 'العنوان الكامل',
    postal_code_label: 'الرمز البريدي',
    city_label: 'المدينة',
    region_label: 'المنطقة',
    password_creation_title: 'كلمة المرور',
    password_check_label: 'تحقق من كلمة المرور',
    personal_information_title: 'المعلومات الشخصية',
    personal_information_description1: 'يرجى تقديم المعلومات التالية كما هو موضح',
    personal_information_description2: 'في جواز سفرك أو بطاقة الهوية.',
    full_name_label: 'الاسم الكامل',
    data_about_user: 'يجب أن تتطابق البيانات مع بيانات جواز السفر أو بطاقة الهوية.',
    date_birth_label: 'تاريخ الميلاد',
    phone_verification_code_label: 'رمز التحقق من الهاتف',
    residensy_title: 'لنقم بالتحقق منك',
    residensy_title_description: 'أولاً، اختر بلد إقامتك.',
    residency_text: 'تأكد من أن بلد إقامتك صحيح.',
    residency_option: "الإقامة",
    citizenship_option: "الجنسية",
    back_btn_text: "رجوع",
    document_select_title: 'التحقق من الوثائق',
    document_select_description: 'حدد بلد/منطقة إصدار الوثيقة.',
    document_select_warning: 'استخدم وثيقة صالحة صادرة عن الحكومة',
    document_select_text1: 'فقط الوثائق المذكورة أدناه ',
    document_select_text2: 'سيتم قبولها؛ أي وثائق أخرى سيتم ',
    document_select_text3: 'رفضها.',
    id_card_text: 'بطاقة الهوية',
    passport_text: 'جواز السفر',
    kyc_info_title: 'التحقق من الوثائق',
    kyc_info_description1: 'التقط صورة للجانبين من وثيقتك الصادرة عن الحكومة ',
    kyc_info_description2: 'بطاقة الهوية.',
    kyc_info_description3: 'جواز السفر',
    reqiurements_kyc1: 'الوثيقة لم تنته صلاحيتها',
    reqiurements_kyc2: 'هذه وثيقة أصلية، وليست نسخة أو مسح ضوئي',
    reqiurements_kyc3: 'قم بإزالة أي حوامل أو أغطية للبطاقات لتجنب الانعكاسات أو التمويه',
    reqiurements_kyc4: 'ضع الوثيقة على خلفية ذات لون موحد',
    reqiurements_kyc5: 'يجب أن تكون الوثيقة مستقيمة، دون ميل أو دوران.',
    picture_card_text: 'قم بإسقاط صورتك هنا أو اختر',
    picture_card_signed_text: 'انقر لتصفح',
    picture_box_front: 'الوجه الأمامي لبطاقة الهوية',
    picture_box_back: 'الوجه الخلفي لبطاقة الهوية',
    picture_passport: 'صورة جواز السفر',
    kyc_person_label: 'التحقق من الشخصية',
    kyc_person_description: 'التقط صورة لنفسك ووثيقتك في يديك.',
    photo_label: 'صورتك',
    person_requirements1: 'يجب أن تكون الصورة واضحة، بدون أي تمويه أو بكسلة.',
    person_requirements2: 'يجب أن يكون كل من وجه المستخدم والوثيقة مرئيين بالكامل وغير مقصوصين.',
    person_requirements3: 'يجب أن تكون المعلومات على الوثيقة واضحة وغير مغطاة بالأصابع.',
    person_requirements4: 'يجب أن تكون الوثيقة مستقيمة، دون ميل أو دوران.',
    verify_btn: 'تحقق',
    verify_main_text: 'دعنا الآن نتحقق من هويتك لاستخدام جميع ميزات Quanta.',
    acccount_created_title: 'تم إنشاء الحساب',
    account_verified_title: 'تم التحقق من الحساب',
    registartion_success_title: 'تم التسجيل بنجاح',
    auth_success_title: 'تم التفويض بنجاح',
    auth_login: 'الآن يمكنك استخدام جميع ميزات Quanta',
    kyc_text: 'سيتم إخطارك بالنتائج لاحقًا.',
    kyc_title: 'تم إرسال KYC للتحقق',
    not_now: 'ليس الآن',
    password_requirements1: "8 رموز على الأقل",
    password_requirements2: "رقم واحد على الأقل",
    password_requirements3: "حرف كبير واحد على الأقل",
    password_requirements4: "يجب أن تتطابق كلمات المرور",
    apple_btn: "التسجيل باستخدام Apple",
    adress_palceholder: "العنوان",
    first_name_placeholder: "الاسم الأول",
    last_name_placeholder: "اسم العائلة",
    invalid_file_type: 'نوع الملف غير صالح. يرجى تحميل صورة بصيغة PNG أو JPEG أو JPG.',
    incorrect_code: 'الرمز المدخل غير صحيح. يرجى المحاولة مرة أخرى.',
    upload_file: 'يرجى تحميل ملف.',
    missing_token: 'رمز التفويض مفقود.',
    picture_missing: 'صورة الأمام أو الخلف مفقودة.',
    document_type: 'نوع الوثيقة أو البلد مفقود.',
    existing_document: 'يوجد بالفعل وثيقة لهذا المستخدم.',
    failed_kyc: 'فشل في تقديم تفاصيل KYC.',
    unexpected_error: 'حدث خطأ غير متوقع. يرجى المحاولة مرة أخرى.',
    password_changed: 'تم تغيير كلمة المرور. الرجوع إلى الصفحة السابقة.',
    failsed_reset_password: 'فشل في إعادة تعيين كلمة المرور.',
    error_occured: 'حدث خطأ أثناء إعادة التعيين.',
    login_failed: 'فشل تسجيل الدخول، يرجى المحاولة مرة أخرى.',
    email_send_error: 'حدث خطأ أثناء إرسال رمز التحقق بالبريد الإلكتروني.',
    parsing_error: 'حدث خطأ غير متوقع أثناء تحليل الاستجابة.',
    documents_under_review: "المستندات قيد المراجعة بالفعل",
    email_already_exist: "البريد الإلكتروني موجود بالفعل",
};
