export var it_locale = {
    next_btn: 'Avanti',
    have_an_account: 'Hai già un account?',
    log_in: 'Accedi',
    sign_up: "Accedi / Registrati",
    auth_title1: 'Benvenuto',
    auth_title2: 'su Quanta',
    line_text: 'o',
    sign_up_with_google: 'Registrati con Google',
    sign_up_with_apple: 'Registrati con Apple',
    log_in_title: 'Bentornato',
    email_phone_input_label: 'Email/Numero di telefono',
    password_input_label: 'Password',
    didt_remember_password: 'Non ricordi la password?',
    change_password_title: 'Nuova password',
    password_check_input_label: 'Controlla password',
    reset_password_title: 'Reimposta la password',
    email_input_label: 'Email',
    email_verification_title: 'Verifica email',
    email_verification_description: 'Inserisci il codice di verifica a 6 cifre che è stato inviato a',
    email_verification_description2: 'Se non vedi l\'email nella tua casella di posta in arrivo, controlla la cartella dello spam o della posta indesiderata, potrebbe essere stata filtrata lì.',
    email_code_title: 'Codice email',
    wait_code: 'Attendere per inviare nuovamente il codice',
    not_get_code: 'Non hai ricevuto il codice?',
    phone_verification_title: 'Verifica del telefono',
    phone_verification_text1: 'Inserisci il codice di verifica a 6 cifre',
    phone_verification_text2: 'che è stato inviato al tuo numero di telefono',
    input_phone_label: 'Telefono',
    input_code_label: 'Codice',
    creating_account_title: 'Creazione di un account',
    terms_and_policy1: 'Creando un account',
    terms_and_policy2: ', accetto',
    terms_and_policy3: ' i ',
    terms_and_policy4: 'Termini di utilizzo',
    terms_and_policy5: ' e ',
    terms_and_policy6: 'Politica sulla privacy',
    email_verification_code1: 'Inserisci il codice di verifica a 6 cifre',
    email_verification_code2: 'che è stato inviato a',
    email_verification_code3: 'Il codice è valido per 30 minuti.',
    email_verification_label: 'Codice di verifica e-mail',
    home_adress_title: 'Indirizzo di casa',
    home_adress_description: 'Assicurati che questo sia il tuo indirizzo attuale.',
    full_adress_label: 'Indirizzo completo',
    postal_code_label: 'Codice postale',
    city_label: 'Città',
    region_label: 'Regione',
    password_creation_title: 'Password',
    password_check_label: 'Controlla la password',
    personal_information_title: 'Informazioni personali',
    personal_information_description1: 'Fornisci le seguenti informazioni come indicato',
    personal_information_description2: 'sul tuo passaporto o carta d\'identità.',
    full_name_label: 'Nome completo',
    data_about_user: 'I dati devono corrispondere a quelli nel passaporto o nella carta d\'identità.',
    date_birth_label: 'Data di nascita',
    phone_verification_code_label: 'Codice di verifica del telefono',
    residensy_title: 'Verifichiamoti',
    residensy_title_description: 'Prima di tutto, seleziona il tuo paese di residenza.',
    residency_text: 'Assicurati che il tuo paese di residenza sia corretto.',
    residency_option: "Residenza",
    citizenship_option: "Cittadinanza",
    back_btn_text: "Indietro",
    document_select_title: 'Verifica del documento',
    document_select_description: 'Seleziona il paese/regione di emissione del documento.',
    document_select_warning: 'Usa un documento valido rilasciato dal governo',
    document_select_text1: 'Solo i seguenti documenti elencati di seguito ',
    document_select_text2: 'saranno accettati; tutti gli altri documenti saranno ',
    document_select_text3: 'rifiutati.',
    id_card_text: "Carta d'identit\u00E0",
    passport_text: 'Passaporto',
    kyc_info_title: 'Verifica del documento',
    kyc_info_description1: 'Scatta una foto di entrambi i lati del tuo documento ',
    kyc_info_description2: 'rilasciato dal governo.',
    kyc_info_description3: 'passaporto',
    reqiurements_kyc1: 'Il documento non è scaduto',
    reqiurements_kyc2: 'Questo è un documento originale, non una scansione o una copia',
    reqiurements_kyc3: 'Rimuovi qualsiasi custodia o copertura della carta per evitare riflessi o sfocature',
    reqiurements_kyc4: 'Posiziona il documento su uno sfondo a tinta unita',
    reqiurements_kyc5: 'Il documento deve essere tenuto dritto, non inclinato o ruotato.',
    picture_card_text: 'Trascina qui la tua foto o seleziona',
    picture_card_signed_text: 'Clicca per sfogliare',
    picture_box_front: 'Fronte della carta d’identità',
    picture_box_back: 'Retro della carta d’identità',
    picture_passport: 'Foto del passaporto',
    kyc_person_label: 'Verifica dell’identità',
    kyc_person_description: 'Scatta una foto di te stesso con il documento in mano.',
    photo_label: 'Foto di te stesso',
    person_requirements1: "L'immagine deve essere nitida, senza sfocature o pixel.",
    person_requirements2: 'Sia il viso dell’utente che il documento devono essere completamente visibili e non ritagliati.',
    person_requirements3: 'Le informazioni sul documento devono essere chiaramente visibili e non coperte dalle dita.',
    person_requirements4: 'Il documento deve essere tenuto dritto, non inclinato o ruotato.',
    verify_btn: 'Verifica',
    verify_main_text: 'Ora verifichiamo la tua identità per utilizzare tutte le funzionalità di Quanta.',
    acccount_created_title: 'account creato',
    account_verified_title: 'account verificato',
    registartion_success_title: 'Registrazione avvenuta con successo',
    auth_success_title: 'Autorizzazione riuscita',
    auth_login: 'Ora utilizza tutte le funzionalità di Quanta',
    kyc_text: 'Verrai avvisato sui risultati in seguito.',
    kyc_title: 'KYC inviato per la verifica',
    not_now: 'Non ora',
    password_requirements1: "Almeno 8 caratteri",
    password_requirements2: "Almeno 1 numero",
    password_requirements3: "Almeno 1 lettera maiuscola",
    password_requirements4: "Le password devono corrispondere",
    apple_btn: "Registrati con Apple",
    adress_palceholder: "Indirizzo",
    first_name_placeholder: "Nome",
    last_name_placeholder: "Cognome",
    invalid_file_type: 'Tipo di file non valido. Carica un\'immagine in formato PNG, JPEG o JPG.',
    incorrect_code: 'Il codice inserito è errato. Riprova.',
    upload_file: 'Carica un file.',
    missing_token: 'Manca il token di autorizzazione.',
    picture_missing: 'Manca l\'immagine del documento (fronte o retro).',
    document_type: 'Manca il tipo di documento o il paese.',
    existing_document: 'Esiste già un documento per questo utente.',
    failed_kyc: 'Impossibile inviare i dettagli KYC.',
    unexpected_error: 'Si è verificato un errore imprevisto. Riprova.',
    password_changed: 'Password cambiata. Torna alla pagina precedente.',
    failsed_reset_password: 'Impossibile reimpostare la password.',
    error_occured: 'Si è verificato un errore durante il reset.',
    login_failed: 'Accesso non riuscito. Riprova.',
    email_send_error: 'Errore nell\'invio del codice di verifica via email.',
    parsing_error: 'Si è verificato un errore imprevisto durante l\'analisi della risposta.',
    documents_under_review: "I documenti sono già in revisione",
    email_already_exist: "L'email esiste già",
};
