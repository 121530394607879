var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { AuthInput, AuthInputLabel, DocumentLink, FormContainer, FormContainerText, LogInTitle, SignUpForm, StyledCheckbox, StyledFormContainer, TextContainerStyle, VerifyText, } from "../../../assets/styles/logIn.style";
import { MainBtnStyle, StyledCol, StyledRow } from "../../../assets/styles/mainPageStyles";
import { BackArrow } from "../../../components/authComponents/BackButton";
import { isEmail } from "../../../const/validations/validation";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../../store/user/setUser";
import validator from "validator";
import { FormattedMessage, useIntl } from "react-intl";
import { Alert } from "../../../components/authComponents/Alert";
// Functional component for the "Creating Account" page
export var CreatingAccount = function () {
    // State to manage the input value (email/phone) and checkbox state
    var _a = useState(""), inputValue = _a[0], setInputValue = _a[1];
    var _b = useState(false), isCheckboxChecked = _b[0], setIsCheckboxChecked = _b[1];
    var _c = useState(""), error = _c[0], setError = _c[1];
    var _d = useState(false), showAlert = _d[0], setShowAlert = _d[1];
    var navigate = useNavigate(); // Hook to navigate between routes
    var addProp = useUserStore().addProp; // Zustand store to save user data
    var intl = useIntl(); // React Intl hook for localization
    // Validation function: checks if the input is a valid email or phone number and if the checkbox is checked
    var isFormValid = (isEmail(inputValue) || validator.isMobilePhone(inputValue)) && isCheckboxChecked;
    // Updates the input value state when the user types
    var handleInputChange = function (e) {
        setInputValue(e.target.value);
    };
    var sendUserEmailExist = function (_a) { return __awaiter(void 0, [_a], Promise, function (_b) {
        var response, exists, error_1;
        var email = _b.email;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch("".concat(process.env.REACT_APP_API_URL, "/user/exist-email"), {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify({ email: email })
                        })];
                case 1:
                    response = _c.sent();
                    if (!response.ok) {
                        throw new Error("Server error");
                    }
                    return [4 /*yield*/, response.json()];
                case 2:
                    exists = _c.sent();
                    return [2 /*return*/, exists];
                case 3:
                    error_1 = _c.sent();
                    setError("unexpected_error");
                    console.error("Error sending request:", error_1);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // Validates the form and ensures it meets all requirements before submission
    var validateForm = function () {
        var isEmailValid = isEmail(inputValue);
        var isPhoneValid = validator.isMobilePhone(inputValue);
        return (isEmailValid || isPhoneValid) && isFormValid;
    };
    // Updates the checkbox state when clicked
    var handleCheckboxChange = function (e) {
        setIsCheckboxChecked(e.target.checked);
    };
    // Handles form submission
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var isSuccessful;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!isEmail(inputValue)) return [3 /*break*/, 2];
                    addProp("email", inputValue);
                    return [4 /*yield*/, sendUserEmailExist({ email: inputValue })];
                case 1:
                    isSuccessful = _a.sent();
                    if (isSuccessful) {
                        setError("email_already_exist");
                        setShowAlert(true);
                        return [2 /*return*/];
                    }
                    navigate("/registration/email", { state: { email: inputValue, sendVerificationCode: true } });
                    return [3 /*break*/, 3];
                case 2:
                    if (validator.isMobilePhone(inputValue)) {
                        addProp("phone", inputValue);
                        navigate("/registration/phone", { state: { phone: inputValue } });
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (
    // Page layout with a responsive row and column setup
    _jsxs(StyledRow, { "$justifyContent": "center", style: { height: '100%' }, children: [_jsxs(StyledCol, { "$lg": 7, "$md": 12, children: [_jsx(BackArrow, { position: "absolute", top: "10%", navigationLink: "/" }), _jsx(StyledCol, { "$justifyContent": "center", "$lg": 12, "$md": 12, "$sm": 12, "$xs": 12, children: _jsxs(FormContainer, { children: [_jsx(FormContainerText, { children: _jsx(LogInTitle, { children: _jsx(FormattedMessage, { id: "creating_account_title", defaultMessage: "Creating an account" }) }) }), _jsxs(SignUpForm, { onSubmit: handleSubmit, children: [_jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: "email_phone_input_label", defaultMessage: "Email/Phone number" }) }), _jsx(AuthInput, { placeholder: intl.formatMessage({ id: 'email_phone_input_label', defaultMessage: 'Email/Phone number' }), value: inputValue, onChange: handleInputChange, "data-testid": "email-phone-input", type: "email", role: "email", required: true }), _jsxs(StyledFormContainer, { children: [_jsx(StyledCheckbox, { type: "checkbox", checked: isCheckboxChecked, onChange: handleCheckboxChange, "data-testid": "checkbox" }), _jsxs(TextContainerStyle, { children: [_jsx(VerifyText, { children: _jsx(FormattedMessage, { id: "terms_and_policy1", defaultMessage: "By creating an account" }) }), _jsx(FormattedMessage, { id: "terms_and_policy2", defaultMessage: ", I agree to" }), _jsx("br", {}), _jsx(FormattedMessage, { id: "terms_and_policy3", defaultMessage: " the " }), _jsx(DocumentLink, { href: "#", children: _jsx(FormattedMessage, { id: "terms_and_policy4", defaultMessage: "Terms of Use" }) }), _jsx(FormattedMessage, { id: "terms_and_policy5", defaultMessage: " and " }), _jsx(DocumentLink, { href: "#", children: _jsx(FormattedMessage, { id: "terms_and_policy6", defaultMessage: "Privacy Policy" }) }), "."] })] }), _jsx(MainBtnStyle, { type: "submit", width: "50%", disabled: !isFormValid, style: {
                                                opacity: validateForm() ? "100%" : "50%",
                                                cursor: validateForm() ? "pointer" : "not-allowed",
                                            }, children: _jsx(FormattedMessage, { id: "next_btn", defaultMessage: "Next" }) })] })] }) })] }), showAlert && error && (_jsx(Alert, { message: error, onClose: function () { return setShowAlert(false); }, isSuccess: false }))] }));
};
