var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthBackgroundPage, AuthInput, AuthInputLabel, AuthLine, AuthRowContainer, EcosystemCol, LogInContainer, LogInTitle, SpaceBackground, VerifyText } from "../../../assets/styles/logIn.style";
import { EcosystemRightBlock, MainBtnStyle, PositionLayout, StyledCol, StyledRow } from "../../../assets/styles/mainPageStyles";
import PersonIcon from '../../../assets/img/User-icon.svg';
import AutoPlay from "../../../components/ecosystem/AutoCarousel";
import axios from "axios";
import { useLocaleStore } from "../../../store/locale/localeStore";
import { GoogleButton } from "../../../components/authComponents/GoogleButton";
import Header from "../../../components/header/Header";
import { FormattedMessage, useIntl } from "react-intl";
import { AppleButton } from "../../../components/authComponents/AppleButton";
// import { Spinner } from "react-bootstrap";
import { Spinner } from "../../../components/authComponents/Spinner";
import validator from "validator";
import { useUserStore } from "../../../store/user/setUser";
import { useUserLoginStore } from "../../../store/user/setUser";
import AbstractCircle from '../../../assets/img/Abstract-icon.svg';
import AvsrtartBlob from '../../../assets/img/left-circles.svg';
import TokenService from "../../../utils/tokenService/tokenService";
/**
 * Authentication Page component for login and registration.
 * It includes localized content, language selection, social login buttons,
 * and an autoplaying ecosystem carousel section.
 */
export var AuthPage = function (_a) {
    var currentLocale = _a.currentLocale, onLocaleChange = _a.onLocaleChange;
    // State to store localized content
    var _b = useState(null), localeContent = _b[0], setLocaleContent = _b[1];
    var navigate = useNavigate();
    var location = useLocation();
    var intl = useIntl();
    var _c = useState(""), inputValue = _c[0], setInputValue = _c[1];
    var addProp = useUserStore().addProp;
    var _d = useUserLoginStore(), addLoginProp = _d.addLoginProp, userLoginObj = _d.userLoginObj;
    var isFormValid = (validator.isEmail(inputValue) || validator.isMobilePhone(inputValue));
    var handleInputChange = function (e) {
        setInputValue(e.target.value);
    };
    var sendUserEmailExist = function (_a) { return __awaiter(void 0, [_a], Promise, function (_b) {
        var response, data, error_1;
        var email = _b.email;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch("".concat(process.env.REACT_APP_API_URL, "/user/exist-email"), {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({ email: email }),
                        })];
                case 1:
                    response = _c.sent();
                    if (!response.ok) {
                        throw new Error("Server error: ".concat(response.status));
                    }
                    return [4 /*yield*/, response.json()];
                case 2:
                    data = _c.sent();
                    return [2 /*return*/, data];
                case 3:
                    error_1 = _c.sent();
                    console.error("Error check email:", error_1);
                    return [2 /*return*/, false];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    // Zustand store to manage selected locale
    var locale = useLocaleStore(function (state) { return state.locale; });
    var setLocale = useLocaleStore(function (state) { return state.setLocale; });
    // useEffect(() => {
    //   const accessToken = Cookies.get("access_token");
    //   const refreshToken = Cookies.get("refresh_token");
    //
    //   console.log({accessToken})
    //   console.log({refreshToken})
    //
    //   if (accessToken && refreshToken) {
    //     TokenService.setTokens(accessToken, refreshToken);
    //     navigate("/success", {state: {from: '/registration/creating-password'}});
    //   }
    // }, [navigate, location]);
    useEffect(function () {
        var params = new URLSearchParams(location.search);
        var accessToken = params.get("access_token");
        var refreshToken = params.get("refresh_token");
        var kyc = params.get("kyc");
        var error = params.get("error");
        if (accessToken && refreshToken) {
            if (kyc == 'true') {
                TokenService.setTokens(accessToken, refreshToken);
                navigate("/success", { state: { from: '/registration/creating-password' } });
            }
            else {
                TokenService.setTokens(accessToken, refreshToken);
                navigate("/success", { state: { from: location.pathname } });
            }
        }
        if (error) {
            console.error("Error check email:", error);
        }
    }, [location, navigate]);
    // Fetch localized content based on selected locale
    useEffect(function () {
        var fetchLocaleContent = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get("".concat(process.env.REACT_APP_API_URL, "/localization/").concat(locale))];
                    case 1:
                        response = _a.sent();
                        setLocaleContent(response.data.ecosystem_block);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.error('Error fetching localized content:', error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchLocaleContent();
    }, [locale]);
    // Show a loading spinner until the content is available
    if (!localeContent) {
        return (_jsx(PositionLayout, { height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", children: _jsx(Spinner, {}) }));
    }
    // Function to change the language (Fix: Recursive function call issue)
    var handleLanguageChange = function (newLocale) {
        setLocale(newLocale);
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var isExist, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, sendUserEmailExist({ email: inputValue })];
                case 2:
                    isExist = _a.sent();
                    if (isExist) {
                        addLoginProp("email", inputValue);
                        navigate("/auth/email-verify");
                    }
                    else {
                        if (validator.isEmail(inputValue)) {
                            addProp("email", inputValue);
                            navigate("/registration/email", {
                                state: { email: inputValue, sendVerificationCode: true }
                            });
                        }
                        else {
                            addProp("phone", inputValue);
                            navigate("/registration/phone", {
                                state: { phone: inputValue }
                            });
                        }
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _a.sent();
                    console.error("Error email check:", error_3);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(SpaceBackground, { children: [_jsxs(AuthBackgroundPage, { children: [_jsx(Header, { currentLocale: currentLocale, onLocaleChange: onLocaleChange }), _jsx(LogInContainer, { style: { display: 'grid', justifyContent: 'unset' }, children: _jsxs(AuthRowContainer, { "$alignItems": "center", children: [_jsx(StyledCol, { "$xl": 5, "$md": 12, "$justifyContent": "center", children: _jsx(PositionLayout, { display: "flex", flexDirection: "column", children: _jsxs(_Fragment, { children: [_jsxs(LogInTitle, { children: [_jsx(FormattedMessage, { id: 'auth_title1' }), " ", _jsx("br", {}), _jsx(FormattedMessage, { id: 'auth_title2' })] }), _jsx(AuthInputLabel, { children: _jsx(FormattedMessage, { id: "email_phone_input_label", defaultMessage: "Email/Phone number" }) }), _jsx(AuthInput, { placeholder: intl.formatMessage({
                                                        id: 'email_phone_input_label',
                                                        defaultMessage: 'Email/Phone number'
                                                    }), value: inputValue, onChange: handleInputChange, "data-testid": "email-phone-input", type: "email", role: "email", required: true }), _jsxs(MainBtnStyle, { width: "100%", style: {
                                                        opacity: isFormValid ? "100%" : "50%",
                                                        cursor: isFormValid ? "pointer" : "not-allowed",
                                                    }, onClick: handleSubmit, disabled: !isFormValid, children: [_jsx("img", { src: PersonIcon, style: { marginRight: "10px" }, alt: "User Icon" }), _jsx(FormattedMessage, { id: "sign_up" })] }), _jsxs(StyledRow, { style: { flexWrap: 'nowrap' }, "$justifyContent": "center", "$alignItems": "center", children: [_jsx(StyledCol, { "$justifyContent": "center", "$lg": 5, "$md": 5, "$sm": 5, "$xs": 5, style: { paddingLeft: '0px', paddingRight: '0px' }, children: _jsx(AuthLine, {}) }), _jsx(StyledCol, { "$alignItems": "center", "$justifyContent": "center", "$lg": 1, "$md": 1, "$sm": 1, "$xs": 1, children: _jsx(VerifyText, { children: _jsx(FormattedMessage, { id: "line_text" }) }) }), _jsx(StyledCol, { "$justifyContent": "center", "$lg": 5, "$md": 5, "$sm": 5, "$xs": 5, style: { paddingLeft: '0px', paddingRight: '0px' }, children: _jsx(AuthLine, {}) })] }), _jsx(GoogleButton, { isLogIn: true }), _jsx(AppleButton, {})] }) }) }), _jsx(EcosystemCol, { "$xl": 7, children: _jsxs(EcosystemRightBlock, { children: [_jsx(PositionLayout, { padding: "0px 0px 0px 7%", children: localeContent.contributors && _jsx(AutoPlay, { data: localeContent.contributors.part1_block }) }), _jsx("div", { children: localeContent.contributors &&
                                                    _jsx(AutoPlay, { data: localeContent.contributors.part2_block, direction: "right" }) }), _jsx("div", { children: localeContent.contributors && _jsx(AutoPlay, { data: localeContent.contributors.part3_block }) })] }) })] }) })] }), _jsx(PositionLayout, { position: "absolute", bottom: "10%", left: "50%", zIndex: 1000, displayXL: "none", children: _jsx("img", { src: AbstractCircle }) }), _jsx(PositionLayout, { position: "absolute", bottom: "70%", left: "0%", children: _jsx("img", { src: AvsrtartBlob }) })] }));
};
