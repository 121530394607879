import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
/**
 * Function to check if the provided JWT token is valid.
 */
var isTokenValid = function (token) {
    if (!token)
        return false;
    try {
        var decoded = jwtDecode(token); // Decode the JWT token
        var currentTime = Math.floor(Date.now() / 1000); // Get the current time in seconds
        return decoded.exp > currentTime; // Check if the token expiration time is in the future
    }
    catch (_a) {
        return false; // Return false if the token is invalid or decoding fails
    }
};
/**
 * ProtectedRoute component ensures that only authenticated users can access certain routes.
 * If the user has a valid JWT token, it renders the child components.
 * Otherwise, it redirects the user to the authentication page.
 */
export var ProtectedRoute = function (_a) {
    var children = _a.children;
    var token = localStorage.getItem("accessToken"); // Retrieve the token from local storage
    if (!isTokenValid(token)) {
        return _jsx(Navigate, { to: '/auth' }); // Redirect to the authentication page if the token is invalid
    }
    return children; // Render the protected component if the token is valid
};
