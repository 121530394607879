import { jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
function CountdownTimer(_a) {
    var time = _a.time;
    var _b = useState(time), seconds = _b[0], setSeconds = _b[1];
    useEffect(function () {
        var interval = setInterval(function () {
            setSeconds(function (prevSeconds) {
                if (prevSeconds <= 1) {
                    clearInterval(interval);
                    return 0;
                }
                return prevSeconds - 1;
            });
        }, 1000);
        return function () { return clearInterval(interval); };
    }, []);
    return (_jsxs("div", { style: { color: '#d4d4d5', textDecoration: 'none' }, children: ["(", seconds, ")"] }));
}
export default CountdownTimer;
